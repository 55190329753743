import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { DealerContext } from '../../shared/DealerContext'
import WebsiteListFilter from './WebsiteListFilter'
import { useSnackbar } from 'notistack'
import { LoadingContext } from '../../shared/context/loadingContext';
import { BASE_URI } from '../../shared/Constants';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    green: {
        color: '#8bc34a'
    },
    hoverTableCell: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
}));

export default function WebsiteTable() {
    const classes = useStyles();
    let history = useHistory();
    const [filteredWebsites, setFilteredWebsites] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('websitename');
    const { selectedMotorGroup, updateSelectedMotorGroup, dealerId } = useContext(DealerContext);
    const [searchString, setSearchString] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [showDialog, setShowDialog] = useState({ show: false, activeDealer: null });
    const [debounceBuild, setDebounceBuild] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const [isLoading, setIsLoading] = useState(true);

    const {
        loading,
        websites,
        total
    } = WebsiteListFilter(searchString, page, pageSize, order, orderBy)

    const [headCells, setHeadCells] = useState([
        { id: 'URL', label: 'URL', sort: true },
        { id: 'dealer', label: 'dealer', sort: true },
        { id: 'motorGroupName', label: 'Motor Group', sort: true }
    ]);

    useEffect(() => {
        if (!selectedMotorGroup || loading) {
            return;
        }
        setFilteredWebsites(websites || []);

    // Set isLoading to false after processing.
        setIsLoading(false)
    }, [websites, selectedMotorGroup, loading]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        setPage(0); // Set the page to 0 when Enter is pressed
        setSearchString(searchInput);
      };

    const handleChangePageSize = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    function handleDealerSelection(dealer,motorgroupId) {
        if (!motorgroupId) return;
        updateSelectedMotorGroup(dealer, motorgroupId);
    }

    let filterSearchWebsite = filteredWebsites.filter(a => searchString === "" || a.dealer.name?.toLowerCase().includes(searchString.toLowerCase())
    || a.websiteUrl?.toLowerCase().includes(searchString.toLowerCase())
    );

    const handleButtonClick = (id, dealer, motorgroupID) => {
        history.push(`/website/edit/${id}`);
        handleDealerSelection(dealer, motorgroupID);
    };

    const handleBuildClick = (id, row) => {
        putBuild(id,row)
    };

    const putBuild = async (id, fields, resetForm) => {
          setDebounceBuild(true);
          showLoading();
          const result = await axios.put(`${BASE_URI}/websiteBuilds/triggerDirectBuilds/${id}`, fields).then((res)=>{
            enqueueSnackbar('build triggered', { variant: 'success' });
          }).catch((error)=>{
            enqueueSnackbar((error.message, 'Failed to trigger the build'), { variant: 'error' });
          }).finally(()=>{
            setDebounceBuild(false);
            hideLoading();
          })
    }

    const handleAddWebsiteClick = () => {
        if (!dealerId) {
          // Show snackbar if dealerId is not selected
          enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
        } else {
          // If dealerId is selected, navigate to the add website page
          history.push('/website/add');
        }
      };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={4} className={classes.stepPadding} direction="column">
                    <Grid item xs={12}>
                        <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
                            <Typography style={{ flex: '1 1' }} variant="h4" id="tableTitle" component="div">
                                Websites
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    name="websiteFilter"
                                    id="websiteFilter"
                                    label="Search Websites"
                                    value={searchInput}
                                    type="text"
                                    InputProps={{
                                    endAdornment: searchInput ? (
                                        <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            disableFocusRipple
                                            disableRipple
                                            onClick={() => setSearchInput('')}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                        </InputAdornment>
                                    ) : '',
                                    }}
                                    onChange={(e)=>setSearchInput(e.target.value)}
                                />

                            </form>
                                <Button onClick={handleAddWebsiteClick}
                                    variant="contained"
                                    style={{ marginLeft: '2rem' }}
                                    startIcon={<AddIcon />}
                                    color="primary">
                                    Website
                                </Button>
                        </Toolbar>

                        <TableContainer>
                            <Table aria-label="users table" size="small">
                                <TableHeadersSort
                                    order={order}
                                    orderBy={orderBy}
                                    headCells={headCells}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filterSearchWebsite
                                 .map((row) => (
                                            <Tooltip title={`Edit ${row.name}`} key={row.id} placement="left">
                                                <TableRow hover
                                                    className={classes.hoverTableCell}
                                                >
                                                    <TableCell onClick={() => handleButtonClick(row.id, row.dealer, row.dealer.motorgroupID)}>
                                                        <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }}
                                                            variant="subtitle2">
                                                            {row.websiteUrl}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell onClick={() => handleButtonClick(row.id, row.dealer, row.dealer.motorgroupID)}>
                                                        {row.dealer?.name}
                                                    </TableCell>
                                                    <TableCell onClick={() => handleButtonClick(row.id, row.dealer, row.dealer.motorgroupID)}>
                                                        {row.dealer.motorGroupName}
                                                    </TableCell  >
                                                    <TableCell>
                                                    <Button
                                                        className={classes.removedealerbtn}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleButtonClick(row.id, row.dealer, row.dealer.motorgroupID)}>
                                                        View
                                                    </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                    <Button
                                                        className={classes.removedealerbtn}
                                                        variant="contained"
                                                        disabled={debounceBuild}
                                                        color="primary"
                                                        onClick={() => handleBuildClick(row.id, row)}>
                                                        Build
                                                    </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </Tooltip>
                                        ))
                                    }
                                    {(!filteredWebsites.length && !isLoading) && (
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center" style={{ borderBottom: 0 }} className="py-3">
                                            <Typography variant="h6" color="textSecondary">
                                                No Websites Added
                                            </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {
                                        isLoading &&
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center"
                                                style={{ borderBottom: 0 }}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    }

                                </TableBody>
                            </Table>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            SelectProps={{
                                inputProps: {
                                'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            count={total}
                            page={page}
                            rowsPerPage={pageSize}
                            component="div"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangePageSize}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}