import React,{useEffect} from 'react';
import './App.css';
import { createTheme, ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ResetPasswordComponent from './components/login/ResetPasswordComponent';
import {SnackbarProvider} from 'notistack';
import LoadingProvider from './shared/context/loadingContext';
import {LeadMainProvider} from "./shared/contexts/LeadMainContext";
import {TabsProvider} from "./shared/contexts/TabsContext";
import {ActionProvider} from "./shared/contexts/ActionTabContext";
import {ReportingProvider} from "./shared/contexts/ReportingContext";
import AuthGuard from './shared/context/AuthGuard';
import DashboardContainer from './components/dashboardContainer/DashboardContainer';

const theme = createTheme({
    overrides: {
        MuiTextField: {
            root: {
                marginBottom: '1rem'
            }
        },
        MuiTypography: {
            h5: {
                textTransform: 'capitalize'
            },
            subtitle2: {
                fontSize: '1rem'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '1.5rem 1rem'
            }
        }
    }
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}>
                <LoadingProvider>
                    <Router>
                            <LeadMainProvider>
                                <TabsProvider>
                                    <ReportingProvider>
                                        <ActionProvider>
                                            <Route path="/" render={() => {
                                               return <AuthGuard component={DashboardContainer}/>;
                                            }} />

                                            <Route path="/resetPassword/:token" render={() => {
                                                return <ResetPasswordComponent />;
                                            }} />
                                        </ActionProvider>
                                    </ReportingProvider>
                                </TabsProvider>
                            </LeadMainProvider>
                    </Router>
                </LoadingProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
