import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';

export default function CategoryFilter(searchString = '', pageNumber = 1, pageSize = 10, order = 'asc', orderBy = 'name', isAdmin) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [total, setTotal] = useState(0);
    const { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setCategories([]);
    }, [searchString, pageNumber, pageSize, order, orderBy, isAdmin, dealerId]);

    useEffect(() => {
        setError(false);
        const params = {
            pageNumber,
            pageSize,
            order,
            orderBy,
            isAdmin
        };

        if (dealerId) {
            params.dealerId = dealerId;
        }

        if (searchString) {
            params.searchString = searchString;
        }

        setLoading(true);

        axios({
            method: 'GET',
            url: `${BASE_URI}/Categories`,
            params
        }).then((response) => {
            if (response.data?.list) {
                setCategories(response.data.list);
                setTotal(response.data.total);
            }
        }).catch(error => {
            if (axios.isCancel(error)) return;
            setError(true);
        }).finally(() => {
            setLoading(false);
        });
    }, [searchString, pageNumber, pageSize, order, orderBy, dealerId, isAdmin]);

    return { loading, error, categories, total };
}
