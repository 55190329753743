import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from '@material-ui/core/AppBar';
import { Box, IconButton } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import DealerAdminForm from './DealerAdminForm';
import DealerStaff from './DealerStaff';
import DealerWebsiteAdminForm from './DealerWebsiteAdminForm';
import DealerPrivateNotes from './DealerPrivateNotes';
import DealerSettingForm from './DealerSettingForm';
import DealerImportersForm from './DealerImportersForm';
import DealerSocialMedia from './DealerSocialMedia';
import DealerActivatedServiceForm from './DealerActivatedServiceForm';
import DealerFacebookLeads from './DealerFacebookLeads';
import WebsiteLeads from './WebsiteLeads';
import DealerMedia from './DealerMedia/DealerMedia';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { BASE_URI, ROLE_DEALER_ADMIN } from '../../shared/Constants';
import { AuthContext } from '../../shared/context/AuthContext';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2rem',
        width: '100%',
        position: 'relative',
    },
    tabsContainer: {
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        paddingRight: '3rem', // Padding to ensure space for the scroll buttons
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        background: theme.palette.background.paper,
        borderRadius: '50%',
        boxShadow: theme.shadows[3],
    },
    buttonLeft: {
        left: 0,
    },
    buttonRight: {
        right: 0,
    },
}));

export default function DealerContainer({ match }) {
    const classes = useStyles();
    let history = useHistory();
    const [tabIndex, setTabIndex] = useState(0);
    const [dealer, setDealer] = useState();
    const { id } = useParams();
    const { role } = useContext(AuthContext);
    const tabsContainerRef = useRef(null);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const hideForRole = () => {
        if (role === ROLE_DEALER_ADMIN) {
            return true;
        }
        return false;
    };

    const tabs = [
        { id: 0, label: 'Dealer Info', comp: <DealerAdminForm history={history} match={match} /> },
        { id: 1, label: 'Dealer Staff', comp: <DealerStaff />},
        { id: 2, label: 'Website', comp: <DealerWebsiteAdminForm />,  hide: hideForRole() },
        { id: 3, label: 'Private Notes', comp: <DealerPrivateNotes />,},
        { id: 4, label: 'Lead Routing', comp: <DealerSettingForm />, hide: hideForRole() },
        { id: 5, label: 'Stock Importer', comp: <DealerImportersForm />,  hide: hideForRole()  },
        { id: 6, label: 'Social Media', comp: <DealerSocialMedia /> },
        { id: 7, label: 'Activated Services', comp: <DealerActivatedServiceForm />, hide: hideForRole() },
        // { id: 8, label: 'Facebook Leads', comp: <DealerFacebookLeads />, hide: hideForSelectionDealerRole() },
        { id: 8, label: 'Website Leads', comp: <WebsiteLeads />},
        { id: 9, label: 'Media', comp: <DealerMedia />,  hide: hideForRole() }
    ];

    const getDealer = async () => {
        try {
            const result = await axios.get(`${BASE_URI}/dealers/${id}`);
            setDealer(result.data);
        } catch (error) {
            // Handle error
        }
    };

    useEffect(() => {
        getDealer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            {dealer && <h2>Editing Dealer: {dealer.name}</h2>}
            <AppBar position="static">
                <div className={classes.tabsContainer} ref={tabsContainerRef}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="dealer tabs" variant="scrollable">
                        {tabs.filter(tab => !tab.hide).map((step, index) => (
                            <Tab key={index} disableRipple label={step.label} />
                        ))}
                    </Tabs>
                </div>
            </AppBar>
            <Box mt={4}>
                {tabs.filter(tab => !tab.hide).map((step, index) => (
                    <React.Fragment key={index}>
                        {tabIndex === index ? step.comp : null}
                    </React.Fragment>
                ))}
            </Box>
        </div>
    );
}
